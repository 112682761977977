import {
  isCreatorBlackNovember,
  hasCouponBlackNovember
} from 'utils/campaigns';
import { eventsBlackNovember } from '../constants';

export function labelClickCardBlackNovember(isBlackNovemberActive: boolean, product: Product) {
  let eventLabel = '';
  const productHasCouponBlackNovember = hasCouponBlackNovember(
    product?.coupons
  );

  if (productHasCouponBlackNovember) {
    eventLabel = eventsBlackNovember.PRODUCT_DISCOUNT;
  } else if (
    isCreatorBlackNovember(isBlackNovemberActive, product?.campaigns)
  ) {
    eventLabel = eventsBlackNovember.CREATOR_BN;
  }

  return eventLabel;
}
