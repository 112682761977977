/* eslint-disable no-empty */

export const currentScrollPosition = () => {
  try {
    return (
      (typeof document !== 'undefined' && document.documentElement.scrollTop) ||
      (typeof window !== 'undefined' && window.pageYOffset)
    );
  } catch (err) {}
  return null;
};

export const scrollTo = (x : number, y : number) => {
  try {
    if (typeof window !== 'undefined') {
      window.scroll(x, y);
    }
  } catch (err) {}
  return null;
};
