import { axios } from '@hotmart/request';
import Cookie from 'js-cookie';
import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import { publicIpv4 } from 'public-ip';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { featureSwitchKey } from '@core/data/constants/feature-switch';
import Service from '@core/data/service/service';
import {
  getFormattedPriceProduct,
  mountPayloadProductsOffer
} from '@core/presentation/utils/prices';
import Body from 'components/Body';
import HtmlHead from 'components/HtmlHead';
import {
  sendDefaultPageview,
  sendImpressions,
  sendSearchCategoryNoResultsPageview,
  sendSearchFilterNoResultsPageview,
  sendSearchNoResultsPageview
} from 'components/Results/events/';
import useSWRMutation from 'swr/mutation';
import { getCookieParsed, getHmVlcIntegration } from 'utils/cookies';
import { labelClickCardBlackNovember } from 'utils/events';
import { getLanguageFromUrlOrCookie, isPortuguese } from 'utils/language';
import { extractFeatureValueByKey } from 'utils/object';
import { mountPriceTitle } from 'utils/price';
import { getRemoteAddr, sanitize } from 'utils/string';
import {
  extractDataFromQuery,
  isOrganicSearch,
  mountMarketplaceHomePage,
  mountMarketplaceResultsPage
} from 'utils/url';
import config from '../../../env.config';
import SearchService from '../../../src/@marketplace/service';
import Breadcrumb from '../../../src/components/Breadcrumb';
import PageContainer from '../../../src/components/PageContainer';
import { MarketplaceContext } from '../../../src/components/core/Provider/MarketplaceProvider';
import { featureTags, sectionCampaign } from '../../../src/constants';
import CampaignBanner from '../../../src/containers/Category/CampaignBanner';
import ResultsContainer from '../../../src/containers/Results';
import withApplyFilter from '../../../src/containers/Results/withApplyFilter';
import {
  extractDetailsCampaign,
  getDataCampaign
} from '../../../src/utils/campaigns';
import { productsArray } from '../../../src/utils/checkout';
import * as DatahubService from '../../../src/utils/datahub';
import {
  CLICK_SOURCES,
  EVENT_ACTIONS,
  SEARCH_SOURCES
} from '../../../src/utils/datahub.constants';
import { sendAnalyticsEvent } from '../../../src/utils/datalayer';
import { deleteUndefined } from '../../../src/utils/object';
import { currentScrollPosition, scrollTo } from '../../../src/utils/scroll';

const ScriptLoader = dynamic(() => import('components/ScriptLoader'), {
  ssr: false
});

const BlackNovemberBanner = dynamic(
  () => import('components/Banner/BlackNovember'),
  {
    loading: () => <div />,
    ssr: false
  }
);

function ResultsPage(props) {
  const {
    query,
    cookieParsed,
    resultsData,
    t,
    applyFilter,
    router,
    originalUrl,
    fullUrl,
    locale,
    environment,
    project,
    ip,
    seasonInfo,
    isMobile,
    locales,
    bannerCampaign,
    resolvedUrl,
    useQualityScoreQueries,
    featureSwitch
  } = props;

  const [hotfeature, setHotfeature] = useState(featureTags.MARKETPLACE);
  const [prices, setPrices] = useState([]);
  const [priceLoading, setPriceLoading] = useState(false);
  const [searchId, setSearchId] = useState(null);
  const sessionId = Cookie.get('sid');
  const marketplaceContext = useContext(MarketplaceContext);
  const {
    actions,
    changeState,
    marketplace,
    isBlackNovemberActive
  } = marketplaceContext;
  const productsPriceToggle = extractFeatureValueByKey(
    featureSwitch,
    featureSwitchKey.SHOW_NEW_CHECKOUT
  );
  const isForceGatewayActive = query?.ppgateway || false;
  const isPriceGatewayActive =
    productsPriceToggle?.value || isForceGatewayActive;
  const creatorModalRef = useRef(null);
  let resultsPage;

  const searchTerm = query?.q || '';

  const title = query?.q
    ? t('results_page.browser_title', { searchTerm: query?.q })
    : t('search_page.browser_title');

  const activeCampaign =
    bannerCampaign &&
    Object.keys(bannerCampaign).length > 0 &&
    bannerCampaign.BANNER_RESULTS_PAGE_IMAGE &&
    bannerCampaign;

  useEffect(() => {
    changeState('showSearch', false);

    if (isOrganicSearch(document?.referrer)) {
      const finalFeature = `${hotfeature},${featureTags.ORGANIC}`;
      setHotfeature(finalFeature);
    }
  }, []);

  useEffect(() => {
    actions.setQueryParams({
      ...query,
      hotfeature
    });
  }, [hotfeature]);

  const sendPageView = (totalProducts, params) => {
    const isEmptyResultsPage = totalProducts === 0;
    if (!isEmptyResultsPage) {
      sendDefaultPageview(params, locale, cookieParsed);
    } else if (params.topics?.length || params.topifications?.length) {
      sendSearchCategoryNoResultsPageview(params);
    } else if (
      params?.categories.trim() !== '' ||
      params?.formats.trim() !== '' ||
      params?.optionsPayment.trim() !== ''
    ) {
      sendSearchFilterNoResultsPageview(params, cookieParsed);
    } else if (isEmptyResultsPage) {
      sendSearchNoResultsPageview(params, locale, cookieParsed);
    }
  };

  const showEventDataHub = () => {
    const event = useQualityScoreQueries
      ? 'test-quality-score'
      : 'current-search';

    DatahubService.sendEvent({
      action: EVENT_ACTIONS.SEARCH,
      locale,
      term: query?.q,
      ip,
      sessionId,
      source: SEARCH_SOURCES.SEARCH,
      IsMobile: isMobile,
      path: window.location.href,
      currentTest: event
    });
  };

  useEffect(() => {
    sendImpressions(resultsData?.requestData?.results);
  }, [locale, resultsData?.requestData?.results]);

  if (marketplace.resultsPage && marketplace.resultsPage?.requested) {
    resultsPage = marketplace.resultsPage;
  } else {
    resultsPage = actions.formatDataForResultsPage({
      ...resultsData,
      loading: false,
      requested: true
    });
  }

  useEffect(() => {
    const newResultsPage = actions.formatDataForResultsPage({
      ...resultsData,
      loading: false,
      requested: true
    });

    showEventDataHub();

    const finalQuery = {
      ...query,
      hotfeature
    };

    actions.setResultsPage(newResultsPage);
    actions.setQueryParams(finalQuery);
    return () => {
      actions.clearResultsPage();
    };
  }, [resultsData]);

  const applyFilterInterceptor = async data => {
    const newProductList = await applyFilter(
      resultsPage.searchText,
      data,
      useQualityScoreQueries,
      router
    );

    scrollTo(0, 0);
    sendImpressions(newProductList?.requestData?.results);
  };

  useEffect(() => {
    sendPageView(resultsPage?.totalProducts, {
      formats: resultsPage?.formats?.join(',') || '',
      categories: resultsPage?.categories?.join(',') || '',
      topifications: resultsPage?.topifications || '',
      topics: resultsPage?.topics || '',
      optionsPayment: resultsPage?.optionsPayment?.join(',') || '',
      ratings: resultsPage?.ratings?.join(',') || '',
      languages: resultsPage?.languages?.join(',') || '',
      searchTerm: resultsPage?.searchText || '',
      initialSelectedCategory: query?.initialSelectedCategory || ''
    });
  }, [
    resultsPage?.totalProducts,
    resultsPage?.searchText,
    resultsPage?.formats,
    resultsPage?.optionsPayment,
    resultsPage?.categories,
    resultsPage?.topifications,
    resultsPage?.topics,
    resultsPage?.ratings || [],
    resultsPage?.languages,
    query?.initialSelectedCategory
  ]);

  useEffect(() => {
    const id = uuid();
    setSearchId(id);

    DatahubService.sendEvent({
      action: EVENT_ACTIONS.REPLY,
      sessionId,
      data_version: '2.0',
      sort: resultsPage?.sort || 'most_relevant',
      products: (resultsData?.requestData?.results || []).map((item, idx) => ({
        id: item.productId,
        productPosition: idx
      })),
      searchId: id,
      topifications:
        resultsData?.requestData?.topification_term?.topifications || [],
      classificationId: resultsData?.requestData?.topification_term?.hash || '',
      searchTerm: resultsPage?.searchText || ''
    });
  }, [resultsPage?.sort]);

  const loadMoreHandler = async () => {
    const loadedProducts = await actions.loadMoreResultsPage(
      useQualityScoreQueries
    );
    const currentScrollYPosition = currentScrollPosition();

    if (currentScrollYPosition) {
      scrollTo(0, currentScrollYPosition);
    }

    sendImpressions(loadedProducts?.requestData?.results);
    DatahubService.sendEvent({
      action: EVENT_ACTIONS.REPLY,
      sessionId,
      data_version: '2.0',
      products: (loadedProducts?.requestData?.results || []).map(
        (item, idx) => ({
          id: item.productId,
          productPosition: idx
        })
      ),
      topifications:
        resultsData?.requestData?.topification_term?.topifications || [],
      classificationId: resultsData?.requestData?.topification_term?.hash || '',
      searchId,
      searchTerm: resultsPage.searchText || ''
    });
  };

  const onProductClick = (product = {}) => {
    const eventLabel = labelClickCardBlackNovember(
      marketplaceContext?.isBlackNovemberActive,
      product
    );

    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Marketplace:SearchResults',
      event_action: `Click:ProductCard${
        product.certification ? ':Certification' : ''
      }`,
      event_label: eventLabel || 'Card'
    });
  };

  const onProductBuyButtonClick = () => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_SearchResults: 'Marketplace:Category',
      event_action: 'Click:ProductCard',
      event_label: 'Buy'
    });
  };

  const getPriceTotal = async (products, productIdsArray, discount = false) => {
    const productsPrice = await SearchService.getCheckoutPrice(productIdsArray);

    products.forEach(product => {
      const productReturned = productsPrice.filter(
        productPrice => product.productId === productPrice.productId
      );

      if (productReturned && productReturned.length >= 1) {
        const productInfo = productReturned[0];

        if (!discount) {
          product.price = mountPriceTitle(productInfo);
        } else if (
          discount &&
          product.price !== productInfo?.offerFullPrice?.label
        ) {
          product.priceDiscount = mountPriceTitle(productInfo);
        }
      } else {
        sendAnalyticsEvent({
          event: 'noninteraction',
          event_category: 'Product:NoPrice:Single',
          event_label: `productId-${product.productId}`
        });
      }
    });

    return productsPrice;
  };

  const getProductsPrice = async products => {
    const { featureSwitch, actions } = marketplaceContext;
    const { ENABLE_PRICE_ON_DISPLAY_MARKETPLACE = true } = featureSwitch;
    const { setLoadingPrice } = actions || {};

    if (ENABLE_PRICE_ON_DISPLAY_MARKETPLACE) {
      if (environment !== 'production') {
        setLoadingPrice(true);
        setPriceLoading(true);
      }

      const productIdsArray = productsArray(products, false);
      const productIdsArrayDiscount = productsArray(products, true);

      setPriceLoading(true);

      try {
        axios.defaults.headers.common.ip = ip;
        const productsPrice = await getPriceTotal(products, productIdsArray);
        const productsPriceDiscount = await getPriceTotal(
          products,
          productIdsArrayDiscount,
          true
        );
        setPriceLoading(false);
        setPrices(productsPrice);
        return;
      } catch (e) {
        setPriceLoading(false);
        products.map(product =>
          sendAnalyticsEvent({
            event: 'noninteraction',
            event_category: 'Product:NoPrice:All',
            event_label: `productId-${product.productId}`
          })
        );
      }
    }
  };

  const productsList = useMemo(() => {
    return resultsPage.allProducts
      ? mountPayloadProductsOffer(resultsPage.allProducts, ip)
      : [];
  }, [resultsPage.allProducts]);

  const {
    trigger: triggerLoadProductsPrices,
    isMutating: isLoadingMoreProducts
  } = useSWRMutation([productsList], async () => {
    try {
      let productsPriceListDiscounts = [];

      const productsPriceList = await Service.post(
        `${config.envConfig.PRICES}/public/v1/payment/checkout/load-products`,
        productsList
      );

      if (productsPriceList?.error) {
        sendAnalyticsEvent({
          event: 'noninteraction',
          event_category: 'Product:NoPrice:Gateway',
          event_label: `ResultsPage - Error: ${productsPriceList?.status}`
        });
      }

      if (marketplaceContext?.isBlackNovemberActive) {
        const productsOffersListDiscounts = mountPayloadProductsOffer(
          resultsPage.allProducts,
          ip,
          { coupon: true }
        );

        productsPriceListDiscounts = await Service.post(
          `${config.envConfig.PRICES}/public/v1/payment/checkout/load-products`,
          productsOffersListDiscounts
        );
      }

      resultsPage.allProducts.forEach(product => {
        const productOfferPrice = productsPriceList?.productsResponse?.filter(
          productPrice => product.productId === productPrice?.products[0].id
        );

        if (productsPriceListDiscounts?.productsResponse?.length) {
          product.priceDiscount = getFormattedPriceProduct(
            productsPriceListDiscounts,
            product,
            locale
          );
        }

        if (Array.isArray(productOfferPrice) && productOfferPrice[0]?.products?.length) {
          product.price = getFormattedPriceProduct(
            productOfferPrice[0]?.products[0]?.offer?.paymentMethods,
            locale
          );
        }
      });
      return;
    } catch (e) {
      console.error(e);
    }
  });

  useEffect(() => {
    if (isPriceGatewayActive && resultsPage.allProducts) {
      triggerLoadProductsPrices();
    } else {
      getProductsPrice(resultsPage.allProducts);
    }
  }, [resultsPage.allProducts]);

  useEffect(() => {
    if (isMobile) {
      marketplaceContext?.actions.setShowSearch(true);
    } else {
      marketplaceContext?.actions.setShowSearch(false);
    }
  }, [isMobile]);

  const termNotFoundAnywhere =
    resultsData &&
    Object.prototype.hasOwnProperty.call(
      resultsData,
      'requestDataGroupByCategory'
    ) &&
    Object.keys(resultsData.requestDataGroupByCategory).length === 0;

  return (
    <>
      <ScriptLoader />
      <HtmlHead
        title={title}
        description={t('results_page.browser_description', {
          searchTerm
        })}
        keywords={t('results_page.browser_keywords')}
        originalUrl={originalUrl}
        currentLanguage={locale}
        url={fullUrl}
        locales={locales}
        resolvedUrl={resolvedUrl}
        noindex
      />
      <Body
        lng={locale}
        showCategoryMenu
        searchTerm={searchTerm}
        showFooter={false}
        environment={environment}
        project={project}
        seasonInfo={seasonInfo}
        className="results-page"
      >
        <div className="results-container">
          <div className="results-breadcrumb">
            <Breadcrumb
              homeUrl={mountMarketplaceHomePage(locale)}
              title={`${t(
                'breadcrumb.search_result'
              )}:  ${resultsPage.searchText ?? t('general.all_products')}`}
            />
            <CampaignBanner
              isMobile={isMobile}
              bannerCampaign={activeCampaign}
              source={CLICK_SOURCES.RESULTS}
            />
          </div>
          <PageContainer className="results-page">
            {isBlackNovemberActive && (
              <BlackNovemberBanner
                isMobile={isMobile}
                lng={locale}
                t={t}
                isHeader={isBlackNovemberActive}
              />
            )}
            <ResultsContainer
              allowPaymentFilter={Boolean(isPortuguese(locale))}
              categoriesSelected={resultsPage?.categories}
              topificationsSelected={resultsPage?.topifications}
              topicsSelected={resultsPage?.topics}
              formatsSelected={resultsPage?.formats}
              paymentSelected={resultsPage?.optionsPayment}
              ratingsSelected={resultsPage?.ratings}
              languagesSelected={resultsPage?.languages}
              couponsSelected={resultsPage?.coupons}
              sortSelected={resultsPage?.sort}
              isLoading={resultsPage?.loading}
              productsByCategory={resultsPage?.requestDataGroupByCategory}
              origin={resultsPage?.origin}
              onFilterApply={applyFilterInterceptor}
              onLoadMore={loadMoreHandler}
              productIdPrefix="results-"
              products={resultsPage?.allProducts}
              searchText={resultsPage?.searchText}
              totalProducts={resultsPage?.totalProducts}
              onProductClick={onProductClick}
              onProductBuyButtonClick={onProductBuyButtonClick}
              prices={prices}
              priceLoading={
                isPriceGatewayActive ? isLoadingMoreProducts : priceLoading
              }
              initialSelectedCategory={query.initialSelectedCategory}
              termNotFoundAnywhere={termNotFoundAnywhere}
              searchId={searchId}
              isMobile={isMobile}
              locale={locale}
              useQualityScoreQueries={useQualityScoreQueries}
              creatorModalRef={creatorModalRef}
            />
          </PageContainer>
        </div>
      </Body>
    </>
  );
}

export const getServerSideProps = async props => {
  const { req, query, locale, resolvedUrl, locales } = props;

  const cookies = getCookieParsed(req);
  const cookieParsed = getHmVlcIntegration(cookies);
  const internal = req && config.currentEnv !== 'development';
  let ip = '';
  let UA = '';
  if (req) {
    // if you are on the server and you get a 'req' property from your context
    UA = req.headers['user-agent']; // get the user-agent from the headers
  } else {
    UA = navigator?.userAgent; // if you are on the client you can access the navigator from the window object
  }
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  if (req) {
    ip = getRemoteAddr(
      req.headers['x-forwarded-for'] ||
        (req.connection.remoteAddress === '::1' ||
        req.connection.remoteAddress.match(/::ffff/)
          ? '127.0.0.1'
          : req.connection.remoteAddress)
    );
  } else {
    ip = getRemoteAddr(await publicIpv4());
  }

  // sanitize search term
  const queryQParsed = (query?.q && sanitize(query.q)) || '';
  query.q = queryQParsed;

  // sanitize search category
  const queryCParsed =
    (query &&
      query.initialSelectedCategory &&
      sanitize(query.initialSelectedCategory)) ||
    '';
  query.initialSelectedCategory = queryCParsed;

  // sanitize search category
  const querySortParsed = (query?.sort && sanitize(query.sort)) || '';
  query.sort = querySortParsed || '';

  const useQualityScoreQueries = query?.qs === '1';

  const categories = extractDataFromQuery('categories', query);
  const ratings = extractDataFromQuery('ratings', query);
  const topifications = extractDataFromQuery('topifications', query);
  const topics = extractDataFromQuery('topics', query);
  const formats = extractDataFromQuery('formats', query);
  const optionsPayment = extractDataFromQuery('optionsPayment', query);
  const coupons = extractDataFromQuery('coupons', query);
  const languagesQuery = extractDataFromQuery('languages', query);
  let bannerCampaign = {};
  let setupCampaign = {};
  let featureSwitch = [];

  const language =
    locale || getLanguageFromUrlOrCookie(cookies, query, locale, req.headers);

  const cookiesUser = {
    ga: cookies._ga,
    hotid: cookies.hotid
  };

  try {
    featureSwitch = await SearchService.getFeatureSwitchInternal(internal);
  } catch (e) {
    console.error(e);
  }

  try {
    setupCampaign = getDataCampaign();
    if (setupCampaign?.sections && setupCampaign?.sections.length > 0) {
      const campaign = extractDetailsCampaign(
        setupCampaign.sections,
        sectionCampaign.RESULT_PAGE,
        false,
        locale,
        setupCampaign?.LANGUAGE,
        true
      );

      if (campaign) {
        bannerCampaign = {
          BANNER_RESULTS_PAGE_LINK: setupCampaign.RESULTS_PAGE,
          START_DATE: setupCampaign.START_DATE,
          END_DATE: setupCampaign.END_DATE,
          LANGUAGE: setupCampaign.LANGUAGE,
          SETUP: true,
          ...campaign
        };
      }
    }
  } catch (e) {
    console.error(e);
  }

  // não considera a categoria C caso algum filtro esteja sendo aplicado
  if (categories && categories.length > 0) {
    delete query.c;
  } else if (query.c) {
    // adiciona categoria filtrada em categories
    categories.push(query.c);
  }

  let resultsData = {};

  try {
    const currentYear = new Date().getFullYear();
    resultsData = await SearchService.loadResultsProduct({
      internal,
      categories,
      formats,
      ratings,
      topifications,
      topics,
      optionsPayment,
      coupons,
      couponType: coupons?.length > 0 ? `BLACK_NOVEMBER_${currentYear}` : null,
      loadGroupByCategory: true,
      sort: querySortParsed || '',
      searchText: queryQParsed,
      languages:
        Array.isArray(languagesQuery) &&
        languagesQuery.length >= 1 &&
        languagesQuery,
      lng: language,
      cookies: cookiesUser,
      useQualityScoreQueries
    });
  } catch (err) {
    console.error(err);
  }

  const propsTreatUndefined = deleteUndefined({
    query,
    cookieParsed,
    resultsData,
    showSearchLTR: false,
    ip,
    seasonInfo: setupCampaign,
    bannerCampaign,
    locale,
    userLanguage: language,
    isMobile,
    fullUrl: `${mountMarketplaceResultsPage(locale)}`,
    resolvedUrl,
    locales,
    useQualityScoreQueries,
    featureSwitch
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'footer'])),
      ...propsTreatUndefined
    }
  };
};

export default withTranslation('common')(
  withRouter(withApplyFilter(ResultsPage))
);
