import { sendAnalyticsEvent } from 'utils/datalayer';

export const sendDefaultPageview = (
  params: Record<string, string>,
  locale: string,
  cookieParsed: boolean
) => {
  const defaultPageView = {
    event: 'pageview',
    section: 'Marketplace',
    subsection: 'Search Results',
    subsection2: `${params?.initialSelectedCategory || ''}`,
    language: `${locale || ''}`,
    searchFilterCategories: `${params?.categories || ''}`,
    searchFilterFormats: `${params?.formats || ''}`,
    searchFilterPayment: `${params?.optionsPayment || ''}`,
    logged: cookieParsed ? 'true' : 'false',
    ...(params?.searchTerm ? { searchTerms: params?.searchTerm.trim() } : {})
  };

  sendAnalyticsEvent(defaultPageView);
};

export const sendImpressions = products => {
  if (products && Array.isArray(products) && products.length > 0) {
    sendAnalyticsEvent({
      event: 'ecommerce',
      event_category: 'Enhanced Ecommerce',
      event_action: 'Impressions',
      event_label: '', // @ts-ignore
      ecommerce: {
        impressions: products.map(product => ({
          name: product.title,
          category: product.category,
          list: 'Search Results'
        }))
      }
    });
  }
};

export const sendSearchCategoryNoResultsPageview = (
  params: Record<string, string>,
  locale: string,
  cookieParsed: boolean
) => {
  const searchCategoryNoResults = {
    event: 'pageview',
    section: 'Marketplace',
    subsection: 'Search : No Results - Category 1',
    subsection2: `${params?.initialSelectedCategory || ''}`,
    searchFilterCategories: `${params?.categories || ''}`,
    searchFilterFormats: `${params?.formats || ''}`,
    searchFilterPayment: `${params?.optionsPayment || ''}`,
    language: `${locale || ''}`,
    logged: cookieParsed ? 'true' : 'false',
    ...(params?.searchTerm ? { searchTerms: params?.searchTerm.trim() } : {})
  };

  sendAnalyticsEvent(searchCategoryNoResults);
};

export const sendSearchFilterNoResultsPageview = (
  params: Record<string, string>,
  cookieParsed: boolean
) => {
  const searchFilterNoResults = {
    event: 'pageview',
    section: 'Marketplace',
    subsection: 'Search : No Results - Filters',
    subsection2: '',
    searchFilterCategories: `${params?.categories || ''}`,
    searchFilterFormats: `${params?.formats || ''}`,
    searchFilterPayment: `${params?.optionsPayment || ''}`,
    logged: cookieParsed ? 'true' : 'false',
    ...(params?.searchTerm ? { searchTerms: params?.searchTerm.trim() } : {})
  };

  sendAnalyticsEvent(searchFilterNoResults);
};

export const sendSearchNoResultsPageview = (
  params: Record<string, string>,
  locale: string,
  cookieParsed: boolean
) => {
  const searchNoResults = {
    event: 'pageview',
    section: 'Marketplace',
    subsection: 'Search : No Results',
    subsection2: `${params?.initialSelectedCategory || ''}`,
    searchFilterCategories: `${params?.categories || ''}`,
    searchFilterFormats: `${params?.formats || ''}`,
    searchFilterPayment: `${params?.optionsPayment || ''}`,
    language: `${locale || ''}`,
    logged: cookieParsed ? 'true' : 'false',
    ...(params?.searchTerm ? { searchTerms: params?.searchTerm.trim() } : {})
  };

  sendAnalyticsEvent(searchNoResults);
};
