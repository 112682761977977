import classnames from 'classnames';

function Container({ children, className, style, fullWidth, role }) {
  return (
    <div
      className={classnames(
        'page-container',
        {
          'page-container--no-pading': fullWidth
        },
        className
      )}
      role={role}
      style={style}
    >
      <div className="page-container__content">{children}</div>
    </div>
  );
}

export default Container;
