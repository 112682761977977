import { Component } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import config from '../../../env.config';
import { buildAlternateMetaURL } from '../../utils/url';

class HtmlHead extends Component {
  render() {
    const {
      title,
      url,
      description,
      image,
      video,
      locale,
      keywords,
      category,
      searchTerm,
      originalUrl,
      currentLanguage,
      experiment,
      style,
      resolvedUrl,
      locales,
      noindex,
      isCategory,
      campaign,
      isCampaign
    } = this.props;

    const titleTuned = title || 'Hotmart';
    const imageTuned = image || '/static/images/logo-hm.svg';
    const videoTuned = video || '';
    const categoryTuned = category || '';
    const searchTermTuned = searchTerm || '';

    let experimentId; let experimentVariant;
    if (experiment) {
      experimentId = experiment.id;
      experimentVariant = experiment.variant;
    }

    return (
      <Head>
        <title>{titleTuned}</title>
        <base href={`/${config.envConfig.PATH_UNDER_HOTMART_COM.result}/`} />
        {buildAlternateMetaURL({ urlPath: originalUrl, language: currentLanguage, locales, resolvedUrl, isCategory, category, campaign, isCampaign })}
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=5, minimum-scale=1.0, maximum-scale=5.0"
        />
        <meta name="currentLanguage" content={currentLanguage || ''} />
        <meta name="apple-mobile-web-app-title" content="Hotmart Marketplace" />
        <meta name="application-name" content="Hotmart Marketplace" />
        <link rel="icon" href="static/favicon.ico" />
        <meta name="p:domain_verify" content="7d2b9fbbd7b401898dd773d7e8774d2b" />
        <meta httpEquiv="x-ua-compatible" content="IE=edge" />
        <meta name="description" content={description} />
        <meta name="abstract" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={titleTuned} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageTuned} />
        <meta property="og:video" content={videoTuned} />
        <meta property="og:site_name" content="Hotmart Marketplace" />
        <meta property="og:type" content="Product" />
        <meta property="og:locale" content={locale} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@hotmart" />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:title" content={titleTuned} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageTuned} />

        {categoryTuned && <meta name="category" content={categoryTuned} />}
        {searchTermTuned && <meta name="searchTerm" content={searchTermTuned} />}

        <meta name="theme-color" content="#F04E23" />
        <meta name="msapplication-TileColor" content="#F04E23" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        <meta name="hot:project" content="app-display-marketplace" />
        { noindex && <meta name="robots" content="noindex, nofollow" /> }
        { experimentId && <meta name="hot:experimentId" content={experimentId} /> }
        { experimentVariant >= 0 && <meta name="hot:experimentVariant" content={experimentVariant} /> }


        <link rel="canonical" href={url} />
        <link rel="stylesheet" type="text/css" href={style} />
      </Head>
    );
  }
}

HtmlHead.defaultProps = {
  title: '',
  url: '',
  description: '',
  image: `${config.envConfig.APP.URL}/s/static/images/hotmart-og-image.jpg`,
  keywords: '',
  video: '',
  locale: '',
  link: '',
  theme: 'red'
};

HtmlHead.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  locale: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.string
};

export default HtmlHead;
